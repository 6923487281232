import React, { useState, useEffect, useContext } from 'react'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { MenuOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import authContext from 'contexts/authStatus'
import TweenOne from 'rc-tween-one'
import { addSuccess } from 'redux-flash-messages'

const Logout = () => {
  localStorage.clear()
  addSuccess({ text: 'Successfully Logged Out' })
  Router.push('/auth/signin')
}

const Login = () => {
  Router.push('/auth/signin')
}

const dataSource = ({ loggedIn, allBrokers, navCalculators }) => {
  const router = useRouter()
  const country_slug = router.query.country_slug
  return {
    wrapper: { className: 'header3 home-page-wrapper' },
    page: { className: 'home-page' },
    logo: {
      className: 'header3-logo',
      children: '/static/images/logo.svg',
    },
    Menu: {
      className: 'header3-menu',
      style: { width: loggedIn ? 560 : 460 },
      dataArray: [
        {
          label: country_slug ? (
            <Link href={`/${[country_slug]}/create-post`}>
              <b>Ask Query</b>
            </Link>
          ) : (
            <Link href={`/in/create-post`}>
              <b>Ask Query</b>
            </Link>
          ),
          key: 'item-create-blog',
          className: 'header3-item',
        },
        {
          label: 'Brokers',
          key: 'item0',
          className: 'header3-item',
          children: allBrokers.map((broker, idx) => {
            const { slug } = broker.attributes || {}
            return {
              className: 'item-sub',
              label: (
                <Link href={`/broker/${slug}`}>
                  <div style={{ alignItems: 'center' }}>
                    <div>{broker.attributes.name}</div>
                    <br />
                    <div style={{ fontSize: '0.8rem' }}>
                      {broker.attributes.name} reviews
                    </div>
                  </div>
                </Link>
              ),
              key: `item-${idx}`,
              style: {
                height: 50,
                lineHeight: 0.7,
                display: 'flex',
                alignItems: 'center',
                margin: '10px 30px',
              },
              icon: (
                <img
                  src="https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg"
                  width={30}
                  height={30}
                />
              ),
            }
          }),
        },
        {
          label: 'Calculators',
          key: 'item1',
          className: 'header3-item',
          children: navCalculators.map((calcualtor, idx) => {
            const { slug } = calcualtor.attributes || {}
            return {
              className: 'item-sub',
              label: (
                <Link href={`/calculator/${slug}`}>
                  {calcualtor.attributes.name}
                </Link>
              ),
              key: `item-${idx}`,
            }
          }),
        },
        {
          label: <Link href="https://fintrakk.com">Blog</Link>,
          key: 'item-Blog',
          className: 'header3-item',
        },
        loggedIn && {
          label: <Link href={`/auth/edit`}>Edit Profile</Link>,
          key: 'item-edit',
          className: 'header3-item',
        },
        {
          label: loggedIn ? 'Logout' : 'Login',
          className: 'header3-item ant-btn ant-btn-primary',
          style: { margin: 15, paddingTop: 4 },
          onClick: () => (loggedIn ? Logout() : Login()),
        },
      ],
    },
    mobileMenu: { id: 'mobile-ham', className: 'header3-mobile-menu' },
  }
}

const NavHam = ({ phoneClick, isMobile }) => {
  if (!isMobile) return null
  return (
    /* eslint-disable-next-line */
    <div
      className="header3-mobile-menu"
      onClick={phoneClick}
      style={{ float: 'right' }}
    >
      <MenuOutlined size="large" />
    </div>
  )
}

const Header3 = ({ isMobile, allBrokers, navCalculators, ...props }) => {
  const router = useRouter()
  const { logged } = useContext(authContext)
  const routerSlug = router.query.slug
  const [phoneOpen, setPhoneOpen] = useState(undefined)
  const [loggedIn, setLoggedIn] = useState(logged)

  useEffect(() => {
    if (localStorage?.getItem('authToken')) {
      setLoggedIn(true)
    }
  }, [])

  const phoneClick = () => {
    setPhoneOpen(!phoneOpen)
  }

  const dataSourceWithBrokers = dataSource({
    loggedIn,
    allBrokers,
    navCalculators,
  })

  const navData = dataSourceWithBrokers.Menu.dataArray

  const moment = phoneOpen === undefined ? 300 : null

  return (
    <TweenOne
      component="header"
      animation={{ opacity: 0, type: 'from' }}
      {...dataSourceWithBrokers.wrapper}
      {...props}
    >
      <div
        {...dataSourceWithBrokers.page}
        className={`${dataSourceWithBrokers.page.className}${
          phoneOpen ? ' open' : ''
        }`}
      >
        <TweenOne
          animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
          {...dataSourceWithBrokers.logo}
        >
          <Link href="/">
            <img
              width="100%"
              src={dataSourceWithBrokers.logo.children}
              alt="img"
            />
          </Link>
        </TweenOne>
        <NavHam phoneClick={phoneClick} isMobile={isMobile} />
        <TweenOne
          {...dataSourceWithBrokers.Menu}
          animation={
            isMobile
              ? {
                  x: 0,
                  height: 0,
                  duration: 300,
                  onComplete: (e) => {
                    if (phoneOpen) {
                      e.target.style.height = 'auto'
                    }
                  },
                  ease: 'easeInOutQuad',
                }
              : null
          }
          moment={moment}
          reverse={!!phoneOpen}
        >
          <Menu
            mode={isMobile ? 'inline' : 'horizontal'}
            defaultSelectedKeys={routerSlug ? [`sub-${routerSlug}`] : []}
            theme="light"
            items={navData}
          ></Menu>
        </TweenOne>
      </div>
    </TweenOne>
  )
}

export default Header3
