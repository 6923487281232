import React from 'react'
import { Button, ButtonProps } from 'antd'
import { mixpanel_event } from 'shared/util'

type Props = Partial<ButtonProps> & {
  source: string
}

const WrappedButton: React.FC<Props> = ({ children, ...props }) => {
  const { onClick, ...updatedProps } = props

  const onClickHandler = (event) => {
    if (props.source) {
      mixpanel_event('button.click', { source: props.source })
    }
    if (onClick) {
      onClick(event)
    }
  }
  return (
    <Button onClick={onClickHandler} {...updatedProps}>
      {children}
    </Button>
  )
}

export default WrappedButton
