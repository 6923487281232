import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { removeFlashMessage } from 'redux-flash-messages'
import { message as antMessage } from 'antd'
import PropTypes from 'prop-types'

class FlashMessage extends Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    // eslint-disable-next-line react/no-unused-state
    this.setState({ open: false })
  }

  onFlashMessageClick = (flashMessage) => () => {
    /*
      Make sure the onClick is called when a user clicks
      on the flash message.

      Otherwise callbacks on Flash Messages will not work.
    */
    flashMessage.onClick(flashMessage)

    // This implementation deletes the flash message when it is clicked.
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    this.props.dispatch(removeFlashMessage(flashMessage.id))
  }

  render() {
    const { messages } = this.props
    return (
      <>
        {messages.map((message) =>
          antMessage.info({ content: message.text, key: message.id })
        )}
      </>
    )
  }
}

// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    messages: state.flashMessage.messages,
  }
}

FlashMessage.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default compose(connect(mapStateToProps))(FlashMessage)
