/* global google */
import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
  useMemo,
} from 'react'
import ReactGA from 'react-ga4'
import { useMutation, gql } from '@apollo/client'
import Router, { useRouter } from 'next/router'
import { notification, message } from 'antd'
import ZerodhaAccountButton from 'components/shared/ZerodhaAccount'
import { RightSideBanner } from 'components/shared/banners/zerodha'
import authContext from 'contexts/authStatus'
import authToken from 'contexts/authToken'
import { enquireScreen } from 'enquire-js'
import { CountryEntity } from 'graphql/generated/types'
import FlashMessages from 'helpers/flash-messages'
import Footer from 'layouts/Footer.js'
import Nav3 from 'layouts/header/nav3'
import { parseCookies } from 'nookies'
import { addSuccess } from 'redux-flash-messages'

const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
}

const initGA = () => {
  ReactGA.initialize('G-TVCPZRB2XE')
}

const NotificationMessage = () => (
  <div>
    <div className="hide-mobile" style={{ marginBottom: '8px' }}>
      <RightSideBanner />
    </div>
    <ZerodhaAccountButton type="primary">
      Open Free Zerodha Account
    </ZerodhaAccountButton>
  </div>
)

declare global {
  interface Window {
    Globals: any
    adsbygoogle: any
  }
}

interface ApplicationLayoutProps {
  children: React.ReactNode
  allBrokers: {
    name: string
  }[]
  navCalculators: {
    name: string
  }[]
  allPages: {
    slug: string
  }[]
  country?: CountryEntity
}
const oneTapLogin = gql`
  mutation oneTapLogin($token: String, $country: String) {
    oneTapLogin(token: $token, country: $country)
  }
`
export default function ApplicationLayout(props: ApplicationLayoutProps) {
  const [isMobile, setMobile] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const { country } = props
  const router = useRouter()
  const [oneTap] = useMutation(oneTapLogin)
  const openNotification = (placement) => {
    notification.open({
      message: `Free Share Investment Account with Zerodha`,
      description: <NotificationMessage />,
      placement,
      duration: 0,
      style: { zIndex: 100 },
    })
  }
  const handleLogin = (data) => {
    if (data) {
      localStorage.setItem('authToken', data.jwt)
      localStorage.setItem('userEmail', data.user.email)
      localStorage.setItem('userId', data.user.id)
      addSuccess({ text: 'Sign In Successfull' })
      Router.reload()
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    enquireScreen((b) => {
      setMobile(!!b)
    })

    router.events.on('routeChangeStart', () => {
      notification.destroy()
    })

    const timer1 = setTimeout(() => setShowNotification(true), 8000)

    if (process.env.NODE_ENV === 'production') {
      window.Globals = window.Globals || {}
      if (!window.Globals.GA_INITIALIZED) {
        window.Globals.GA_INITIALIZED = true
        initGA()
      }
      logPageView()

      router.events.on('routeChangeComplete', () => {
        // call previously defined callback if is a function
        // log page
        logPageView()
      })

      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [router])

  useEffect(() => {
    if (showNotification) {
      if (country) {
        if (country?.attributes?.name === 'India') {
          openNotification('bottomRight')
        }
      } else {
        openNotification('bottomRight')
      }
    }
  }, [showNotification, country])

  const [logged, setLogged] = useState(false)
  const [jwt, setJwt] = useState(null)
  const setLogin = (status) => {
    setLogged(status)
  }
  const setToken = (token) => {
    setJwt(token)
  }
  const ContextPage = useMemo(() => ({ logged, setLogin }), [logged])
  const jwtContext = useMemo(() => ({ jwt, setToken }), [jwt])

  useEffect(() => {
    try {
      if (!localStorage?.authToken) {
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        script.async = true
        script.defer = true
        script.onload = () => {
          google.accounts.id.initialize({
            client_id:
              '130680529231-9pppc6vu82aqgspv43nui4o8u9v96f6d.apps.googleusercontent.com',
            callback: (response) => {
              if (response.credential) {
                oneTap({
                  variables: {
                    token: response.credential,
                    country: parseCookies().country,
                  },
                })
                  .then((resp) => {
                    if (
                      resp.data &&
                      resp.data.oneTapLogin &&
                      resp.data.oneTapLogin.jwt &&
                      resp.data.oneTapLogin.user.email
                    ) {
                      handleLogin(resp.data.oneTapLogin)
                    } else {
                      message.error('Sorry Something Went Wrong!')
                    }
                  })
                  .catch((err) => message.error(err))
              }
            },
          })
          google.accounts.id.prompt()
          // Add CSS to the document
          const style = document.createElement('style')
          style.innerHTML = '.qJTHM { z-index: 9999; }'
          document.head.appendChild(style)
        }
        script.onerror = (err) => {
          console.error('Failed to load GSI script:', err)
        }
        document.body.appendChild(script)
      }

      // Convertbox
      const script = document.createElement('script')
      script.src = 'https://cdn.convertbox.com/convertbox/js/embed.js'
      script.async = true
      script.defer = true

      script.id = 'app-convertbox-script'
      script.dataset.uuid = 'c0a9f9a7-1d06-4e67-9984-ba23e4cb185f'

      script.onerror = (err) => {
        console.error('Failed to load CB script:', err)
      }
      document.body.appendChild(script)
    } catch (err) {
      message.error('Please enable cookies to visit this website')
    }
  }, [])
  const { children, allBrokers, navCalculators, allPages } = props
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way.
    if (isValidElement(child)) {
      const elemProps = { isMobile }

      return cloneElement(child, elemProps)
    }

    return child
  })

  const listObjectsWithCount = (objects, count) => {
    return objects.slice(0, objects.length > count ? count : objects.length)
  }
  return (
    <authContext.Provider value={ContextPage}>
      <authToken.Provider value={jwtContext}>
        <div>
          <Nav3
            isMobile={isMobile}
            allBrokers={listObjectsWithCount(allBrokers, 6)}
            navCalculators={listObjectsWithCount(navCalculators, 5)}
          />
          <FlashMessages />
          {childrenWithProps}
          <Footer
            id="Footer1_0"
            key="Footer1_0"
            isMobile={isMobile}
            allPages={allPages}
            country={country}
          />
        </div>
      </authToken.Provider>
    </authContext.Provider>
  )
}

ApplicationLayout.fragments = {
  all_brokers: gql`
    fragment AllBrokers on Broker {
      name
      slug
      details
    }
  `,
  nav_calculators: gql`
    fragment NavCalculators on Calculator {
      name
      slug
    }
  `,
  all_pages: gql`
    fragment AllPages on Page {
      title
      slug
    }
  `,
}
