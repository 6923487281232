import React from 'react'

export function RightSideBanner() {
  return (
    <a href="https://bit.ly/3phrkey1" target="_blank" rel="noreferrer">
      <img
        src="https://assets.fintrakk.com/Zerodha-Banner-252x302.png"
        alt="Zerodha Account"
      />
    </a>
  )
}

export function TallBanner() {
  return (
    <a href="https://bit.ly/3phrkey1">
      <img
        src="https://assets.fintrakk.com/Zerodha-Banner-252x302.png"
        alt="Zerodha Account"
      />
    </a>
  )
}
