import React from 'react'
import Button from 'components/shared/button'
import PropTypes from 'prop-types'

export default function ZerodhaAccountButton({ children, ...props }) {
  return (
    <Button
      href="https://bit.ly/3phrkey1"
      target="_blank"
      source="zerodha-account-button"
      {...props}
    >
      {children}
    </Button>
  )
}

ZerodhaAccountButton.propTypes = {
  children: PropTypes.node.isRequired,
}
