import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Row, Col } from 'antd'
import Button from 'components/shared/button'
import { parseCookies, setCookie } from 'nookies'
import TweenOne from 'rc-tween-one'
import { getChildrenToRender, isImg } from 'shared/util'

const countries = [
  { id: 1, name: 'India', value: 'in', img: '/in.png' },
  { id: 2, name: 'Canada', value: 'ca', img: '/ca.png' },
  { id: 3, name: 'US', value: 'us', img: '/us.png' },
]

const getLiChildren = (data) =>
  data.map((item, i) => {
    const { title, childWrapper, ...itemProps } = item
    if (i !== data.length - 1)
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
        </Col>
      )
    return (
      <Col key={i.toString()} {...itemProps} title={null} content={null}>
        <h2 {...title}>
          {typeof title.children === 'string' && title.children.match(isImg) ? (
            <img src={title.children} width="100%" alt="img" />
          ) : (
            title.children
          )}
        </h2>
        <div {...childWrapper}>{childWrapper.children}</div>
      </Col>
    )
  })

const Footer = (props) => {
  const { allPages } = props
  const router = useRouter()
  if (typeof window !== 'undefined') {
    const cookies = parseCookies()
    if (
      !cookies.country &&
      !(router.route.includes('/article') || router.route.includes('/ipos'))
    ) {
      const country = navigator.language
      setCookie(
        null,
        'country',
        `${country.slice(country.indexOf('-') + 1).toLowerCase()}`,
        {
          path: '/', // cookie path
        }
      )
    }
  }

  const allSlugs = allPages.map((entry) => {
    return { slug: entry.attributes.slug, title: entry.attributes.title }
  })
  const dataSource = {
    wrapper: { className: 'home-page-wrapper footer1-wrapper' },
    OverPack: { className: 'footer1', playScale: 0.2 },
    block: {
      className: 'home-page',
      gutter: 0,
      children: [
        {
          name: 'block0',
          xs: 24,
          md: 6,
          className: 'block',
          title: {
            className: 'logo',
            children: <Link href="/">Fintrakk</Link>,
          },
          childWrapper: {
            className: 'slogan',
            children: [
              {
                name: 'content0',
                children: 'Your finances on track',
              },
            ],
          },
        },
        {
          name: 'block1',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: 'Sections' },
          childWrapper: {
            children: [
              { name: 'link0', href: '/brokers', children: 'Brokers' },
              { name: 'link1', href: '/ipos/all', children: 'IPOs' },
              {
                name: 'link2',
                href: '/financial-terms',
                children: 'Financial Terms',
              },
              {
                name: 'link3',
                href: '/trading-softwares',
                children: 'Softwares',
              },
              { name: 'link4', href: '/calculators', children: 'Calculators' },
              { name: 'link5', href: '/cities', children: 'Cities' },
            ],
          },
        },
      ],
    },
    copyrightWrapper: { className: 'copyright-wrapper' },
    copyrightPage: { className: 'home-page' },
    copyright: {
      className: 'copyright',
      children: (
        <span>
          ©2020 by <a href="https://fintrakk.com">Fintrakk</a> All Rights
          Reserved
        </span>
      ),
    },
  }

  dataSource.block.children.push({
    name: 'block1',
    xs: 24,
    md: 6,
    className: 'block',
    title: { children: 'About Us' },
    childWrapper: {
      children: allSlugs.map((page, pos) => {
        return {
          name: `link${pos}`,
          href: `/page/${page.slug}`,
          children: `${page.title}`,
        }
      }),
    },
  })

  dataSource.block.children.push({
    name: 'block1',
    xs: 24,
    md: 6,
    className: 'block',
    title: { children: 'Select Country' },
    childWrapper: {
      children: (
        <Col>
          {countries.map((item, i) => (
            <Button
              type="default"
              onClick={() => {
                document.cookie = `country=${item.value}`
                router.push(`/${item.value}`)
              }}
              block
              style={{
                border: 0,
                borderRadius: 0,
                height: 30,
                color: '#999',
                backgroundColor: '#001529',
              }}
              key={i}
              source="country-select"
            >
              <Row style={{ alignItems: 'center' }}>
                <Col style={{ paddingLeft: '0px', height: '20px' }}>
                  <Image
                    src={item.img}
                    alt="Picture of the country"
                    width={20}
                    height={20}
                  />
                </Col>
                <Col style={{ paddingLeft: '15px' }}>{`${item.name}`}</Col>
              </Row>
            </Button>
          ))}
        </Col>
      ),
    },
  })

  const childrenToRender = getLiChildren(dataSource.block.children)

  return (
    <div {...props} {...dataSource.wrapper}>
      <div className="footer1">
        <Row {...dataSource.block}>{childrenToRender}</Row>
        <TweenOne
          animation={{ y: '+=30', opacity: 0, type: 'from' }}
          key="copyright"
          {...dataSource.copyrightWrapper}
        >
          <div {...dataSource.copyrightPage}>
            <div {...dataSource.copyright}>{dataSource.copyright.children}</div>
          </div>
          <div className="home-page">
            <b>Disclaimer:</b> The content on this site is for informational and
            educational purposes only. This website is an informational platform
            only and not a substitute for any professional advice. The readers
            and subscribers should take required professional advice before
            making any decisions based on the information given here.
          </div>
        </TweenOne>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  className: 'footer1',
}

export default Footer
